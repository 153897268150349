@tailwind base;
@tailwind components;

@import "components/base";
@import "components/actiontext";
@import "components/alert";
@import "components/animation";
@import "components/announcements";
@import "components/avatars";
@import "components/buttons";
@import "components/code";
@import "components/connected_accounts";
@import "components/direct_uploads";
@import "components/docs";
@import "components/forms";
@import "components/icons";
@import "components/iframe";
@import "components/nav";
@import "components/pagination";
@import "components/search";
@import "components/tabs";
@import "components/trix";
@import "components/typography";
@import "components/util";
@import "components/datatables";

@import 'models/networks';
@import 'models/projects';

@import 'tippy.js/dist/tippy';
@import 'flatpickr/dist/flatpickr';
@import 'dropzone/dist/min/dropzone.min';
@import 'dropzone/dist/min/basic.min';

[readonly='readonly'] {
   pointer-events: none;
}

.js-clipboard-button {
  display: none;
}

.js-clipboard--supported .js-clipboard-button {
  display: initial;
}

// Keeps iOS from zooming in upon focus
@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 16px !important;
  }
}
// body {
//   /* Disables pull-to-refresh but allows overscroll glow effects. */
//   overscroll-behavior-y: contain;
//   -webkit-overflow-scrolling: auto!important;
//   -webkit-tap-highlight-color: transparent;
//
//   position: fixed;
//   overflow: hidden;
// }
// body > .mainwrapper {
//   width: 100vw;
//   height: 100vh;
//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
// }


// Use this utility class for identifying elements. Add "?" to class list
// Use .where for haml, which won't allow the question mark
.\?, .where {
  animation: \?wobble 0.5s ease-in-out alternate infinite;
}

@keyframes \?wobble {
  0% {
    box-shadow: inset 2px 2px red, inset -2px -2px red;
  }
  100% {
    box-shadow: inset 4px 4px red, inset -4px, -4px red;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: none;
  font-size: 16px;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #008ed0;
}
.flatpickr-current-month input.cur-year {
  font-size: 16px;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// .trix-button--icon-heading-1 {
//   display: none;
// }

.x-link {
  cursor: copy;
}
a.x-link::after {
  position: relative;
  content: "﻿↗";
  top: -0.3em;
  font-size: 0.5em;
  margin-left: 0.3em;
}

@tailwind utilities;
