:root {
  --prod-color-rgb: 0, 122, 179; /* #007ab3 hexadecimal */
  --airing-color-rgb: 45, 201, 55; /* #2dc937 hexadecimal */
  --paused-color-rgb: 87, 88, 110; /* #57586E hexadecimal */
  --pulled-color-rgb: 243, 123, 123; /* #F37B7B hexadecimal */
  --alpha-1: .2;
  --alpha-0: 0;
  --alpha-max: 1;
}
$prod-color: rgba(var(--prod-color-rgb),var(--alpha-max));
$airing-color: rgba(var(--airing-color-rgb),var(--alpha-max));
$paused-color: rgba(var(--paused-color-rgb),var(--alpha-max));
$pulled-color: rgba(var(--pulled-color-rgb),var(--alpha-max));

// Project State classes
.state-in-production {
  border: 1px solid rgba(var(--prod-color-rgb),var(--alpha-max));
  color: rgba(var(--prod-color-rgb),var(--alpha-max));
}
.state-airing {
  border: 1px solid $airing-color;
  color: $airing-color;
  background-color: rgba(var(--airing-color-rgb),var(--alpha-1))
}
.state-paused, .state-cancelled, .state-unused, .state-retired {
  border: 1px solid $paused-color;
  color: $paused-color;
  background-color: rgba(var(--paused-color-rgb),var(--alpha-1))
}
.state-pulled {
  border: 1px solid $pulled-color;
  color: $pulled-color;
  background-color: rgba(var(--pulled-color-rgb),var(--alpha-1))
}
.state-in-production-text {
  color: $prod-color;
}
.state-airing-text {
  color: $airing-color;
}
.state-paused-text, .state-cancelled-text, .state-retired-text, .state-paused-text {
  color: $paused-color;
}
.state-pulled-text {
  color: $pulled-color;
}

// Project Phase clasess. Changing gradient backgrounds provide progress info
.phase-initiated, .phase-prepped, .phase-scheduled, .phase-recorded, .phase-edited, .phase-reviewed, .phase-delivered, .phase-distributed, .phase-archived {
  border: 1px solid rgba(var(--prod-color-rgb),var(--alpha-max));
  color: rgba(var(--prod-color-rgb),var(--alpha-max));
}
.phase-initiated {
  background-image: linear-gradient(to right,
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0))
  );
}
.phase-prepped {
  background-image: linear-gradient(to right,
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0))
  );
}
.phase-scheduled {
  background-image: linear-gradient(to right,
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0))
  );
}
.phase-recorded {
  background-image: linear-gradient(to right,
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0))
  );
}
.phase-edited {
  background-image: linear-gradient(to right,
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0))
  );
}
.phase-reviewed {
  background-image: linear-gradient(to right,
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0))
  );
}
.phase-delivered {
  background-image: linear-gradient(to right,
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0))
  );
}
.phase-distributed {
  background-image: linear-gradient(to right,
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-1)),
    rgba(var(--prod-color-rgb),var(--alpha-0))
  );
}
.phase-archived {
  background-image: linear-gradient(to right,
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0)),
    rgba(var(--prod-color-rgb),var(--alpha-0))
  );
}
