/* purgecss start ignore */
b-network-parent {
  border-color: "#048dca";
}
b-network-latino {
  border-color: "#f15c22";
}
b-network-daretodream {
  border-color: "#5e2c75";
}
b-network-french {
  border-color: "#a9010e";
}
b-network-praisehim {
  border-color: "#f3c304";
}
b-network-kids {
  border-color: "#1ab714";
}
b-network-proclaim, b-network-radio, b-network-radiomusic, b-network-latinoradio, b-network-radioaustralia, b-network-radiorussia {
  border-color: "#7ec8ea";
}

//
//
// .network-parent, bg-network-parent  {
//   @apply bg-network-parent;
// }
// .network-latino, bg-network-latino {
//   @apply bg-network-latino;
// }
// .network-daretodream, bg-network-daretodream {
//   @apply bg-network-daretodream;
// }
// .network-french, bg-network-french {
//   @apply bg-network-french;
// }
// .network-praisehim, bg-network-praisehim {
//   @apply bg-network-praisehim;
// }
// .network-kids, bg-network-kids {
//   @apply bg-network-kids;
// }
// .network-proclaim, bg-network-proclaim {
//   @apply bg-network-proclaim;
// }
// .network-russia, bg-network-russia {
//   @apply bg-network-russia;
// }
// .network-international, bg-network-international {
//   @apply bg-network-international
// }
// .network-spark {
//   @apply bg-network-spark;
// }
// .network-radio, bg-network-spark {
//   @apply bg-network-radio;
// }
// .network-radiomusic, bg-network-radiomusic {
//   @apply bg-network-radiomusic;
// }
// .network-latinoradio, bg-network-latinoradio {
//   @apply bg-network-latinoradio;
// }
// .network-radioaustralia, bg-network-radioaustralia {
//   @apply bg-network-radioaustralia;
// }
// .network-radiorussia, bg-network-radiorussia{
//   @apply bg-network-radiorussia;
// }
/* purgecss end ignore */
