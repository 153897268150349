// These three "select" styles are resets from tailwindcss/forms.css styles - Fixes something clobbering them along the way.
select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  border-color: #2563eb;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}

.form-group {
  @apply mb-6 relative;
}

/* non-input elements (like the Stripe card form) can be styled to look like an input */
div.form-control, .form-control, div.disabled-form-control, .disabled-form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  padding-top: .5rem;
  padding-right: .75rem;
  padding-bottom: .5rem;
  padding-left: .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 2.5rem;

  @apply mt-1 block w-full rounded-md bg-white border-gray-200 shadow-sm;
}

.form-control {
  @apply focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50;

  &.error {
    @apply border-red-300;
  }
}

div.disabled-form-control, .disabled-form-control {
  @apply bg-gray-100 border-gray-100;
}

label,
.label {
  @apply block text-sm font-medium leading-5 text-gray-700 mb-1;
}

.form-hint {
  @apply text-gray-600 text-xs italic;

  &.error {
    @apply text-red-500 text-sm italic leading-normal;
  }
}

.select {
  @apply block w-full mt-1 rounded-md border-gray-300 shadow-sm bg-gray-100 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50;
}

.caret {
  @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800;
}

form.inline {
  .form-group {
    @apply flex justify-start items-center;
  }

  .form-group .input {
    @apply flex-1;
  }

  .form-group .label {
    @apply w-1/4;
  }
}

// Fix flatpickr
.flatpickr-current-month .flatpickr-monthDropdown-months {
  display: inline-block;
}

.form-checkbox {
  @apply rounded border-gray-300 text-primary-600 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50;

  &[disabled] {
    @apply bg-gray-200;
  }
}

.input-file {
  height: auto;
  z-index: 2;
  cursor: pointer;

  @apply opacity-0 inline-block pl-0 pr-0 py-3 px-3 overflow-hidden absolute border-none;

  + label {
    @apply inline-flex text-sm font-bold text-center px-4 py-0 rounded no-underline items-center justify-between cursor-pointer;

    height: 40px;
    line-height: 40px;
    transition: ease 0.3s background, ease 0.3s transform, ease 0.2s color;

    @extend .btn;
    @extend .btn-tertiary;
    //@extend .outline;

    &:focus {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}

/* Override the browser form field autofill background color*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*Change text in autofill textbox*/
// input:-webkit-autofill {
//     -webkit-tex


// .dropzone {
//   @apply border-2 rounded-lg border-gray-400 border-dashed;
//
//   &.dz-drag-hover {
//     @apply border-2 rounded-lg border-gray-200 border-dashed;
//
//     .dz-message {
//       opacity: 0.9;
//     }
//   }
// }
